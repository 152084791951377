<template>
	<div id="app">
		<div class="tool-header" v-if="$route.path !== '/'">
			<router-link to="/" style="color: #007bff;text-decoration: none;"><h2>快工具箱</h2></router-link>
		</div>
		<router-view></router-view>
		<div class="footer">
			<p>版权所有 &copy; 2010 快工具箱 - 我向风而行 追寻自由的呼唤</p>
		</div>
		<return-button />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>
