<template>
	<div class="home-container home-page">
		<div class="header">
			<h1 @mouseover="changeHeaderText" @mouseleave="resetHeaderText">{{ headerText }}</h1>
		</div>

		<div class="search-box">
			<el-input placeholder="搜索所有工具" v-model="searchText" @input="search" />
		</div>

		<div class="tool-categories">
			<div v-if="filteredCategories[0].tools.length === 0" class="no-result-message">没有找到匹配的工具</div>
			<div class="tool-box" v-for="(category, index) in filteredCategories" :key="index"
				v-if="category.tools.some(tool => tool.show)">
				<el-card fit>
					<div slot="header" class="card-header"><i :class="category.ico || 'el-icon-takeaway-box'"></i>
						{{ category.name }}
					</div>
					<div class="card-body">
						<el-row :gutter="10">
							<el-col class="tool-col" :span="12" v-for="(tool, i) in category.tools" :key="i"
								v-if="tool.show">
								<router-link :to="{ name: tool.route }" style="color: #007bff;text-decoration: none;">
									<div class="tool-button">{{ tool.name }}</div>
								</router-link>
							</el-col>
						</el-row>
					</div>
				</el-card>
			</div>
		</div>

	</div>
</template>


<script>
	import toolsData from '@/assets/tools.json';

	export default {
		data() {
			return {
				// 工具分类对象
				categories: toolsData.categories,
				// 搜索框文本
				searchText: '',
				// 页面标题
				headerText: '快工具箱'
			};
		},
		created() {
			this.printStyledText();
		},
		computed: {
			// 根据搜索框文本过滤出符合条件的工具按钮
			filteredCategories() {
				if (!this.searchText) {
					return this.categories;
				}

				const searchText = this.searchText.toLowerCase();

				// 过滤出符合条件的工具数组
				const filteredTools = [];
				for (const category of this.categories) {
					for (const tool of category.tools) {
						if (tool.name.toLowerCase().indexOf(searchText) !== -1) {
							// 如果工具名称匹配，将show标志设置为true，并将其添加到过滤后的工具数组中
							tool.show = true;
							filteredTools.push(tool);
						} else {
							// 否则，将show标志设置为false
							tool.show = false;
						}
					}
				}
				// 返回一个新的分类对象，其中包含过滤后的工具数组
				return [{
					name: '搜索结果',
					tools: filteredTools,
				}];
			}
		},
		methods: {
			// 当输入框的值发生变化时调用的搜索方法
			search() {
				for (const category of this.categories) {
					for (const tool of category.tools) {
						if (tool.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
							// 如果工具名称匹配，将show标志设置为true
							tool.show = true;
						} else {
							// 否则，将show标志设置为false
							tool.show = false;
						}
					}
				}
			},
			changeHeaderText() {
				this.headerText = '我向风而行 追寻自由的呼唤';
			},
			resetHeaderText() {
				const headerTextOptions = [
					'🏎️ 快工具箱 🏎️',
					'⚡ 快工具箱 ⚡',
					'🚀 快工具箱 🚀',
					'🏃 快工具箱 🏃',
					'🌪️ 快工具箱 🌪️'
				];
				const randomIndex = Math.floor(Math.random() * headerTextOptions.length);
				this.headerText = headerTextOptions[randomIndex];
			},
			printStyledText() {
				let style = 'color: #007bff; font-size: 15px; font-weight: bolder;';
				console.log('%c 快工具箱 by kuiwaiwai' + '\n Gitee: https://gitee.com/kuiwaiwai/k-toolbox', style);
			}
		}
	};
</script>

<style lang="scss">
	.home-page {
		.header {
			min-height: 90px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.header h1 {
			font-family: "钉钉进步体 Regular";
			color: #007bff;
		}

		.header h1:hover {
			color: #5F9EA0;
		}

		@media (max-width: 767px) {
			.header h1:hover {
				font-size: 1.5rem;
			}
		}

		.search-box {
			margin-top: 20px;
			width: 100%;
			height: auto + 5px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.el-input {
			width: 100%;
		}

		.tool-categories {
			margin-top: 40px;
		}

		.no-result-message {
			font-size: 18px;
			color: #888;
			margin-top: 20px;
			text-align: center;
		}

		.el-card {
			margin-bottom: 10px;
			border-radius: 10px;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			background-color: #fdfdfd;
		}

		@media (max-width: 767px) {
			.tool-col {
				width: 100%;
			}
		}

		.card-header {
			color: #007bff;
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 10px;
		}

		.tool-button-wrapper {
			width: 100%;
			margin-bottom: 8px;
		}

		.tool-button {
			display: block;
			margin-bottom: 8px;
			text-align: center;
			border: #007bff 2px solid;
			border-radius: 3px;
			background-color: white;
			padding: 6px;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
		}

		.tool-button:hover {
			background-color: #007bff;
			color: white;
			border: 2px solid #ccc;
		}
	}
</style>
